.ViewQuoteBreakdownDialog {
  .ant-modal-body {
    padding: 0 0 10px 0;
  }

  .ViewQuoteBreakdownDialog-ControlButtons {
    display: flex;
    justify-content: space-between;
    padding: 20px 20px 0 20px;

    > h1 {
      font-size: 30px;
      font-weight: 300;
    }

    > button {
      font-weight: normal;
      margin-right: -10px;
      margin-top: -10px;
    }
  }

  .ConfigurableCard {
    height: 100%;

    .ConfigurableCard-Body {
      padding: 5px 10px;
      display: inline-block;
      line-height: 1.3;
      width: 100%;
      height: calc(100% - 24px);
    }
  }

  .ViewQuoteBreakdownDialog-ScrollablePanel {
    max-height: calc(100vh - 142px);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 15px 10px 20px;
  }

  .ViewQuoteBreakdownDialog-QuoteInfo {
    line-height: 1.2;

    > .ant-col {
      > div {
        &:first-child > span {
          &:first-child {
            font-weight: 700;
          }

          &:last-child:not(:first-child) {
            color: @colorRed;
            font-weight: 700;
          }
        }

        > span:last-child {
          margin-left: 4px;
        }
      }

      &:first-child > div:last-child > span:last-child {
        color: @colorRed;
        font-weight: 700;
        margin-left: 4px;
      }

      &:last-child > div {
        &:nth-child(2) > span {
          font-style: italic;
        }

        &:nth-child(3) > span:last-child {
          font-weight: 700;
        }

        > span:last-child:not(:first-child) {
          margin-left: 4px;
        }
      }
    }
  }

  .ViewQuoteBreakdownDialog-ItemizationSection {
    margin-top: 5px;

    .ant-col {
      h2 {
        font-size: 18px;
        font-weight: 300;
        text-decoration: underline;
        margin-bottom: 0;
      }

      p {
        line-height: 1.2;
      }

      &:first-child {
        padding-right: 40px !important;

        div {
          display: flex;
          justify-content: space-between;
          line-height: 1.2;

          span:last-child {
            text-transform: uppercase;
          }
        }
      }
    }
  }

  .ViewQuoteBreakdownDialog-NoticeSection {
    margin-top: 8px;

    > p {
      font-size: 13px;
      font-weight: 500;
      text-transform: uppercase;
    }

    > span {
      font-size: 11px;
      font-style: italic;
      line-height: 1.3;
      display: inline-block;
    }
  }

  .ViewQuoteBreakdownDialog-AddressSection {
    margin-top: 18px;
    margin-bottom: 10px;
  }

  .ViewQuoteBreakdownDialog-PiecesSection {
    .ViewQuoteBreakdownDialog-PiecesSection__Preview {
      padding: 5px 0;

      .ant-col {
        font-size: 16px;
        color: @colorGreyBluish2;

        &:nth-child(2) {
          text-align: center;
        }

        &:last-child {
          text-align: end;
        }

        > span:last-child {
          margin-left: 4px;
          font-weight: 700;
        }
      }
    }

    .new-quote-package-header {
      margin: 0 -10px;
      background-color: @colorWhite8;
      font-size: 14px;
    }

    .NewShipmentPackage-PreviewBody {
      padding: 5px 0;
    }

    .NewShipmentPackage-BilledWeight {
      color: @colorGreyBluish2;

      .NewShipmentPackage-BilledWeightValue {
        color: @colorRed;
      }
    }
  }
}

// Buttons: "Back", "Save & Next"
.NewShipmentTopControls {
  display: flex;

  > * {
    .space-between(@spaceNorm2, horizontal);
  }

  .ant-btn {
    width: 80px;
  }
  .ant-btn.ant-btn-primary {
    width: 150px;
  }
}

.RedLink {
  font-weight: 700;
  color: @colorRed;
  line-height: 1;

  @media (min-width: @screen-md-min) {
    font-size: @font-size-base;
  }
  @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
    font-size: @font-size-sm-plus;
  }
  @media (max-width: @screen-xs-max) {
    font-size: @font-size-sm;
  }

  // Firefox renders in a buggy way if a parent has underline and a child contains text
  > * {
    text-decoration: underline;
  }

  .TitleWithExtra & {
    .absolute-middle-to-right();
    font-size: @font-size-sm-plus;
  }
}

.NewShipment-LinkAboveFirstSection {
  position: absolute;
  display: flex;
  align-items: center;
  font-size: @font-size-sm-plus;

  .responsive-sm-vs-md(
    @sm-and-smaller: {
      left: @spaceNorm;
      top: 4px;
    },
    @md-and-bigger: {
      left: @spaceNorm2;
      top: 4px;
    }
    );
}

// Add button inside card
.AddOrCopyPackageButtons {
  display: flex;
  align-items: center;
  .IconButton {
    color: @colorRed;
    margin-right: @spaceNorm0_5;

    &:first-child {
      font-size: 21px;
    }
    &:nth-child(2) {
      font-size: 20px;
    }
  }
  .text {
    margin-left: @spaceNorm0_5;
    font-size: @font-size-lg;
    line-height: 1;
    font-weight: 500;
    color: @colorGreyBluish2;
  }
}

// Whole package form
.NewShipmentPackageListForm {
  // Standalone add button
  .ConfigurableCard + .AddPackageButton {
    margin-top: -@spaceSm;
    margin-left: @spaceNorm;
  }
  // Bottom buttons: "Save", "Cancel"
  .NewShipmentBottomControls {
    > .ant-btn.ant-btn-primary {
      .responsive-sm-vs-md(
        @sm-and-smaller: {
          width: auto;
          flex: 1;
        },
        @md-and-bigger: {
          width: 200px;
          flex: initial;
        }
        );
    }
  }
}

.NewShipmentPackage-ControlButtons {
  display: flex;
  align-items: center;

  .IconButton {
    display: inline-block;
    color: @colorWhite;
    .space-between(@spaceNorm);
  }
}

.NewShipmentBottomControls {
  display: flex;
  justify-content: flex-end;
  padding: 0;

  .md() {
    > .ant-btn.ant-btn-primary {
      width: 240px;
    }
  }
  .sm() {
    > .ant-btn.ant-btn-primary {
      width: 120px;
    }
  }
  .xs() {
    > .ant-btn.ant-btn-primary {
      width: 110px;
    }
  }

  @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
    .md();
  }
  @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
    .sm();
  }
  @media (max-width: @screen-xs-max) {
    .xs();
  }
  .reponsive-force-md & {
    .md();
  }
  .reponsive-force-sm & {
    .sm();
  }
  .reponsive-force-xs & {
    .xs();
  }

  > .ant-btn {
    height: @input-height-md;

    .responsive-sm-vs-md(
      @sm-and-smaller: {
        .space-between(@spaceNorm);
      },
      @md-and-bigger: {
        .space-between(@spaceNorm2);
      }
      );
  }
}

.link-device-to-job-page {
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: 100%;

  .TwoLines {
    display: none;
  }

  button {
    width: 100%;
    height: 40px;
    margin-top: 24px;
  }

  > video {
    height: calc(100% - 395px);
  }

  .ant-select {
    width: 100%;
  }

  .job-details span:first-child {
    font-weight: 700;
    color: @colorGrey3;
    margin-right: 4px;
  }

  .anticon-barcode {
    font-size: @heading-3-size;
    color: @colorGreyBluish3;
  }
}

.ant-modal-mask {
  .mask(80);
  backdrop-filter: blur(3px);
  // To be above popups
  z-index: 1031;
}

.ant-modal-wrap {
  z-index: 1032;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 120px;
  overflow: hidden;

  .ant-modal {
    position: relative;
    top: 0;
  }
}

.ant-modal-wrap.ant-modal-centered {
  text-align: initial;
  align-items: center;
  padding-top: 0;

  &::before {
    display: none;
  }

  .ant-modal {
    display: block;
    text-align: initial;
    flex: initial;
    margin: 0;
  }
}

.ant-modal {
  padding-bottom: 0;
}

.ant-message-notice .ant-message-notice-content {
  border-radius: @borderRadiusXs;
  padding: 0;
  box-shadow: none;
  background-color: transparent;
  box-shadow: 0 1px 20px @colorBlack0_7;
}

.ant-message-custom-content {
  display: flex;
  align-items: center;
  min-height: 30px;
  white-space: nowrap;
  border-radius: @borderRadiusXs;
  padding: 0 @spaceSm;
  gap: @spaceSm;
  color: @colorWhite;
  font-family: @font4;
  font-weight: bold;

  > * {
    display: block;
  }

  @{fa-selector} {
    font-size: 20px;
  }

  &.ant-message-success {
    background-color: @colorLightGreen;
  }
  &.ant-message-error {
    background-color: @colorRed;
  }
  &.ant-message-info {
    background-color: @colorOrange2;
  }

  &.ant-message-warning {
    background-color: @colorWhite2;
  }
}
